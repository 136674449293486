/**
 * Reads application settings from metadata elements inside index.html.
 * This way settings can be conveniently modified and
 * configured by environment in automation pipelines, even after the code is
 * built by webpack tasks.
 */
function readFromMetadataElement(settingId: string): string {
  const element = document.getElementById(settingId);

  if (element === null) {
    throw new Error(`Missing "${settingId}" metadata element.`);
  }

  const value = element.getAttribute("content");

  if (!value) {
    throw new Error(`Invalid "${settingId}" metadata element: missing value.`);
  }

  return value;
}

class ServiceSettings {
  private _apiURL: string;
  private _monitoringKey: string;

  public get apiURL(): string {
    return this._apiURL;
  }

  public get monitoringKey(): string {
    return this._monitoringKey;
  }

  constructor() {
    const apiURL = readFromMetadataElement("api-url");
    this._apiURL = apiURL === "/" ? "" : apiURL;
    this._monitoringKey = readFromMetadataElement("monitoring-key");
  }
}

export default new ServiceSettings();
