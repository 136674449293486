export interface ValidationResult {
  error?: string;
}

export class ValidationRule<T, VT> {
  name: string;
  fn: (context: T, value: VT) => Promise<ValidationResult>;

  constructor(
    name: string,
    fn: (context: T, value: VT) => Promise<ValidationResult>
  ) {
    this.name = name;
    this.fn = fn;
  }
}

export interface ValidationContext {
  errors: {[key: string]: string | undefined};
}

export interface SchemaValidationResult<T> {
  errors: {[K in keyof T]?: string};

  hasErrors: boolean;
}

// Executes a series of promises sequentially, stopping at the first that fails
function serialPromise<T>(funcs: Array<() => Promise<T>>): Promise<T[]> {
  return funcs.reduce(
    (promise, func) =>
      promise.then((result) =>
        func().then(Array.prototype.concat.bind(result))
      ),
    Promise.resolve<T[]>([])
  );
}

export class ValidationSchema<T> {
  rules: {[K in keyof T]: ValidationRule<T, any>[]};
  onValidation: (result: SchemaValidationResult<T>) => void;

  constructor(
    rules: {[K in keyof T]: ValidationRule<T, any>[]},
    onValidation: (result: SchemaValidationResult<T>) => void
  ) {
    this.rules = rules;
    this.onValidation = onValidation;
  }

  validateField(context: T, fieldName: keyof T): Promise<ValidationResult[]> {
    const rules = this.rules[fieldName];

    return serialPromise(
      rules.map((rule) => {
        return () => {
          return rule.fn(context, context[fieldName]);
        };
      })
    );
  }

  validate(context: T, silent?: boolean): Promise<SchemaValidationResult<T>> {
    return new Promise((resolve, reject) => {
      const promises: Promise<ValidationResult[]>[] = [];
      const errors: {[K in keyof T]?: string} = {};

      for (const key in this.rules) {
        promises.push(
          new Promise((resolve, reject) => {
            this.validateField(context, key).then(
              (result?: ValidationResult[]) => {
                if (result) {
                  const firstFailed = result.find(
                    (item) => item !== undefined && !!item.error
                  );
                  if (firstFailed) {
                    errors[key] = firstFailed.error;
                  }
                }
                resolve();
              },
              () => {
                errors[key] = "Validation failed";
                reject();
              }
            );
          })
        );
      }

      Promise.all(promises).then(
        () => {
          const result = {errors, hasErrors: !!Object.keys(errors).length};

          if (silent !== true) this.onValidation(result);
          resolve(result);
        },
        () => {
          const result = {errors, hasErrors: !!Object.keys(errors).length};
          if (silent !== true) this.onValidation(result);
          reject(result);
        }
      );
    });
  }
}

interface RequiredOptions {
  errorMessage?: string;
  condition?: () => boolean;
}

export class NoValidation extends ValidationRule<any, any> {
  constructor() {
    super(
      "none",
      (context: any, value: any): Promise<ValidationResult> => {
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
    );
  }
}

export class Required<VT> extends ValidationRule<any, VT> {
  errorMessage: string;
  condition?: () => boolean;

  constructor(options?: RequiredOptions) {
    super(
      "required",
      (context: any, value: VT): Promise<ValidationResult> => {
        return new Promise((resolve, reject) => {
          if (this.condition && this.condition() === false) {
            resolve();
            return;
          }
          if (
            value === undefined ||
            value === null ||
            (typeof value === "string" && value.replace(/\s/g, "") === "")
          ) {
            resolve({
              error: this.errorMessage,
            });
          }

          resolve();
        });
      }
    );
    // TODO: support localization
    this.errorMessage = options?.errorMessage || "Pole nie może być puste";
    this.condition = options?.condition;
  }
}

export class Pattern extends ValidationRule<any, string> {
  errorMessage: string;
  pattern: RegExp;

  constructor(pattern: RegExp, errorMessage?: string) {
    super(
      "pattern",
      (context: any, value: string): Promise<ValidationResult> => {
        return new Promise((resolve, reject) => {
          if (!value) {
            resolve();
            return;
          }
          if (!value.match(this.pattern)) {
            resolve({
              error: this.errorMessage,
            });
          }
          resolve();
        });
      }
    );
    this.pattern = pattern;
    this.errorMessage = errorMessage || "Nieprawidłowa wartość";
  }
}

export class PhoneNumber extends Pattern {
  constructor() {
    super(/^\+?[0-9\s]{3,}$/);
  }
}

export class EmailAddress extends Pattern {
  constructor() {
    super(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.+]+)\.([a-zA-Z]{2,5}){1,25}$/);
  }
}
