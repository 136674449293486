import CDSAccomodationsApi from "./api/accomodations";
import CDSApplicationsApi from "./api/applications";
import CDSPersonsApi from "./api/persons";
import CDSVouchersApi from "./api/vouchers";
import {AccomodationsApi} from "./domain/accomodations";
import {ApplicationsApi} from "./domain/applications";
import {PersonsApi} from "./domain/persons";
import {VouchersApi} from "./domain/vouchers";

export interface IServices {
  persons: PersonsApi;
  accomodations: AccomodationsApi;
  applications: ApplicationsApi;
  vouchers: VouchersApi;
}

export const Services: IServices = {
  persons: new CDSPersonsApi(),
  accomodations: new CDSAccomodationsApi(),
  vouchers: new CDSVouchersApi(),
  applications: new CDSApplicationsApi(),
};
