import Head from "./common/head";
import MainBanner from "./common/main-banner";
import React, {ReactElement} from "react";
import Wizard from "./common/wizard/wizard";
import WizardPage from "./common/wizard/page";

export default function Test(): ReactElement {
  return (
    <div>
      <Head title="Fundacja Bullerbyn" />
      <MainBanner>
        <div className="form-container">
          <Wizard
            pages={[
              <WizardPage key="one" title="One">
                <h1>One</h1>
              </WizardPage>,
              <WizardPage key="two" title="Two">
                <h1>Two</h1>
              </WizardPage>,
              <WizardPage key="three" title="Three">
                <h1>Three</h1>
              </WizardPage>,
            ]}
          />
        </div>
      </MainBanner>
    </div>
  );
}
