import React, {Component, ReactElement} from "react";

export interface FieldProps {
  error?: string;
  children?: React.ReactNode;
}

export default class Field extends Component<FieldProps> {
  render(): ReactElement {
    const {error, children} = this.props;
    return (
      <div className={error ? "Mui-error Mui-error ui-invalid" : ""}>
        {children}
        {error && <p className="error-message">{error}</p>}
      </div>
    );
  }
}
