import ServiceSettings from "./settings";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: ServiceSettings.monitoringKey,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export function tryTrackEvent(name: string): void {
  try {
    appInsights.trackEvent({name});
  } catch {
    // do nothing
    console.info("Error while tracking user event", name);
  }
}

export default appInsights;
