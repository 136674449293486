export interface Condition {
  text: string;
  mandatory?: boolean;
}

export const Conditions: Condition[] = [
  {
    text: `Zgodnie z art. 6 ust. 1 lit. a ogólnego rozporządzenia o ochronie danych
    osobowych z dnia 27 kwietnia 2016 roku (Dz. Urz. UE L 119 z 4 maja 2016
    roku), wyrażam zgodę na przetwarzanie przez Fundację Bullerbyn na rzecz
    wspólnoty dzieci i dorosłych z siedzibą w Wilczej Górze, ul. Nieziemska 1,
    05-506 Wilcza Góra danych osobowych moich oraz mojego dziecka/osoby
    pozostającej pod moją opieką zawartych w dokumentacji rejestracyjnej na
    potrzeby organizacji projektu „Wioska Bullerbyn 2024" w celu rejestracji do
    projektu oraz zawartych w karcie kwalifikacyjnej, karcie informacji o
    dziecku, formularzu diet i innych dokumentach przekazanych w celu zapewnienia
    bezpieczeństwa i ochrony zdrowia uczestnika wypoczynku przy ulicy
    Warszawskiej 14, 05-555 Świętochów.`,
    mandatory: true,
  },
  {
    text: `Oświadczam, iż zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
    osobowych z dnia 27 kwietnia 2016 r. zostałem poinformowany przez Fundację
    Bullerbyn, że podanie danych jest dobrowolne aczkolwiek odmowa ich podania
    jest równoznaczna z brakiem możliwości uczestnictwa mojego dziecka/osoby
    pozostającej pod moją opieką w organizowanym przez Fundację Bullerbyn
    wypoczynku dzieci i młodzieży w ramach projektu Wioska Bullerbyn 2024 oraz że
    polityka prywatności jest udostępniona na stronie www.bullerbyn.pl.`,
    mandatory: true,
  },
  {
    text: `Oświadczam, że przyjmuję warunki rezerwacji udziału w niniejszym projekcie
    oraz, że zapoznałem/łam się z OGÓLNYMI WARUNKAMI UCZESTNICTWA oraz
    REGULAMINEM działania dostępnymi na stronie www.wioskabullerbyn.pl i
    przyjmuję je do stosowania. Przyjmuję do wiadomości, że nieuiszczenie
    zaliczki w ciągu 3 dni od dnia potwierdzenia rezerwacji jest równoznaczne z
    moją rezygnacją z udziału.`,
    mandatory: true,
  },
  {
    text: `Przyjmuję do wiadomości, że integralną częścią projektu "Wioska Bullerbyn" są
    zajęcia o charakterze psychoedukacyjnym. Wyrażam zgodę na udział mojej
    rodziny w takich zajęciach.`,
    mandatory: true,
  },
  {
    text: `Przyjmuję do wiadomości, iż Organizator zastrzega sobie prawo do wykonywania
    zdjęć podczas trwania wypoczynku dzieci i młodzieży pn. „Wioska Bullerbyn
    2024" oraz udostępnienia ich rodzicom dzieci z danego turnusu a także
    wykorzystania ich w celu promocji i informacji o działalności edukacyjnej i
    społecznej Fundacji na stronie internetowej, fanpage’u na portalu Facebook
    oraz materiałach promocyjnych.`,
    mandatory: true,
  },
  {
    text: `Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie
    adres e-mail informacji handlowej przez Fundację Bullerbyn na rzecz wspólnoty
    dzieci i dorosłych z siedzibą przy ul. Nieziemska 1, 05-506 Wilcza Góra w
    rozumieniu art. 10 ust. 1 ustawy z 18 lipca 2002 roku o świadczeniu usług
    drogą elektroniczną (Dz. U. 2017 r. nr 144 poz. 1219 z późn. zm.).
    Jednocześnie przyjmuję do wiadomości, że wyrażenie zgody jest dobrowolne a
    zgodę mogę odwołać w każdym czasie.`,
  },
  {
    text: `Wyrażam zgodę na używanie przez Fundację Bullerbyn na rzecz wspólnoty dzieci
    i dorosłych z siedzibą przy ul. Nieziemska 1, 05-506 Wilcza Góra,
    telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, dla
    celów marketingu bezpośredniego zgodnie z art. 172 ustawy z dnia 16 lipca
    2004 r. Prawo telekomunikacyjne (Dz. U. 2018 nr. 171 poz. 138 z późn. zm.).
    Jednocześnie przyjmuję do wiadomości, że wyrażenie zgody jest dobrowolne a
    zgodę mogę odwołać w każdym czasie.`,
  },
];
