import {NamedItem} from "../common/forms/select-named";

// TODO: this should be a localized dictionary
export const Countries: NamedItem[] = [
  {
    id: "b2e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Afganistan",
  },
  {
    id: "b3e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Albania",
  },
  {
    id: "b4e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Algieria",
  },
  {
    id: "b5e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Andora",
  },
  {
    id: "b7e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Anguilla",
  },
  {
    id: "b8e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Angola",
  },
  {
    id: "b9e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Antarktyka",
  },
  {
    id: "bae1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Antigua i Barbuda",
  },
  {
    id: "bbe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Arabia Saudyjska",
  },
  {
    id: "bce1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Argentyna",
  },
  {
    id: "bde1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Armenia",
  },
  {
    id: "bee1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Aruba",
  },
  {
    id: "bfe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Australia",
  },
  {
    id: "c1e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Austria",
  },
  {
    id: "c2e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Azerbejdżan",
  },
  {
    id: "c3e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bahamy",
  },
  {
    id: "c4e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bahrajn",
  },
  {
    id: "c5e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bangladesz",
  },
  {
    id: "c6e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Barbados",
  },
  {
    id: "c7e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Belgia",
  },
  {
    id: "c8e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Belize",
  },
  {
    id: "c9e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Benin",
  },
  {
    id: "cae1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bermudy",
  },
  {
    id: "cbe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bhutan",
  },
  {
    id: "cce1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Białoruś",
  },
  {
    id: "cde1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Boliwia",
  },
  {
    id: "cee1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bonaire, Sint Eustatius i Saba",
  },
  {
    id: "cfe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Botswana",
  },
  {
    id: "d0e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bośnia i Hercegowina",
  },
  {
    id: "d1e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Brunei",
  },
  {
    id: "d2e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Brazylia",
  },
  {
    id: "d3e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Brytyjskie Wyspy Dziewicze",
  },
  {
    id: "d4e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Brytyjskie Terytorium Oceanu Indyjskiego",
  },
  {
    id: "d5e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Burkina Faso",
  },
  {
    id: "d6e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Bułgaria",
  },
  {
    id: "d7e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Chile",
  },
  {
    id: "d8e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Burundi",
  },
  {
    id: "d9e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Chorwacja",
  },
  {
    id: "dae1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Chiny",
  },
  {
    id: "dbe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Cypr",
  },
  {
    id: "dce1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Curaçao",
  },
  {
    id: "dde1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Czarnogóra",
  },
  {
    id: "dee1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Czad",
  },
  {
    id: "dfe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
  },
  {
    id: "e0e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Czechy",
  },
  {
    id: "e1e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Demokratyczna Republika Konga",
  },
  {
    id: "e2e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Dania",
  },
  {
    id: "e3e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Dominikana",
  },
  {
    id: "e4e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Dominika",
  },
  {
    id: "e5e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Egipt",
  },
  {
    id: "e6e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Dżibuti",
  },
  {
    id: "e7e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Erytrea",
  },
  {
    id: "e8e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Etiopia",
  },
  {
    id: "e9e1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Ekwador",
  },
  {
    id: "eae1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Falklandy",
  },
  {
    id: "ebe1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Estonia",
  },
  {
    id: "ece1cc76-cb1d-eb11-a813-000d3a45bf29",
    name: "Filipiny",
  },
  {
    id: "2ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Fidżi",
  },
  {
    id: "2fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Francja",
  },
  {
    id: "30bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Finlandia",
  },
  {
    id: "31bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gabon",
  },
  {
    id: "32bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Francuskie Terytoria Południowe i Antarktyczne",
  },
  {
    id: "34bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Georgia Południowa i Sandwich Południowy",
  },
  {
    id: "35bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gambia",
  },
  {
    id: "36bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gibraltar",
  },
  {
    id: "37bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Ghana",
  },
  {
    id: "38bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Grenada",
  },
  {
    id: "39bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Grecja",
  },
  {
    id: "3abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gruzja",
  },
  {
    id: "3bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Grenlandia",
  },
  {
    id: "3cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Guernsey",
  },
  {
    id: "3dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Guam",
  },
  {
    id: "3ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gujana",
  },
  {
    id: "3fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gujana Francuska",
  },
  {
    id: "40bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gwatemala",
  },
  {
    id: "41bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gwadelupa",
  },
  {
    id: "42bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gwinea Równikowa",
  },
  {
    id: "43bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gwinea Bissau",
  },
  {
    id: "44bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Haiti",
  },
  {
    id: "45bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Gwinea",
  },
  {
    id: "46bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Hiszpania",
  },
  {
    id: "47bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Honduras",
  },
  {
    id: "48bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Holandia",
  },
  {
    id: "49bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Indie",
  },
  {
    id: "4abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Irak",
  },
  {
    id: "4bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Irlandia",
  },
  {
    id: "4cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Izrael",
  },
  {
    id: "4dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Japonia",
  },
  {
    id: "4ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Jersey",
  },
  {
    id: "4fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kajmany",
  },
  {
    id: "50bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Hongkong",
  },
  {
    id: "51bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Indonezja",
  },
  {
    id: "52bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Iran",
  },
  {
    id: "53bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Islandia",
  },
  {
    id: "54bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kamerun",
  },
  {
    id: "55bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Jamajka",
  },
  {
    id: "56bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Katar",
  },
  {
    id: "57bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kenia",
  },
  {
    id: "58bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Jemen",
  },
  {
    id: "59bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kiribati",
  },
  {
    id: "5abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Jordania",
  },
  {
    id: "5bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Komory",
  },
  {
    id: "5cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kambodża",
  },
  {
    id: "5dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Korea Południowa",
  },
  {
    id: "5ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kanada",
  },
  {
    id: "5fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kostaryka",
  },
  {
    id: "60bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kazachstan",
  },
  {
    id: "61bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kuba",
  },
  {
    id: "62bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kirgistan",
  },
  {
    id: "63bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kuwejt",
  },
  {
    id: "64bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kolumbia",
  },
  {
    id: "65bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Laos",
  },
  {
    id: "66bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Kongo",
  },
  {
    id: "67bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Lesotho",
  },
  {
    id: "68bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Korea Północna",
  },
  {
    id: "69bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Liechtenstein",
  },
  {
    id: "6abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Liban",
  },
  {
    id: "6bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Litwa",
  },
  {
    id: "6cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Liberia",
  },
  {
    id: "6dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Libia",
  },
  {
    id: "6ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Luksemburg",
  },
  {
    id: "6fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Łotwa",
  },
  {
    id: "70bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Macedonia",
  },
  {
    id: "71bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Majotta",
  },
  {
    id: "72bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Madagaskar",
  },
  {
    id: "73bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Makau",
  },
  {
    id: "74bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Malawi",
  },
  {
    id: "75bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Malediwy",
  },
  {
    id: "76bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Malezja",
  },
  {
    id: "77bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Malta",
  },
  {
    id: "78bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mali",
  },
  {
    id: "79bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Martynika",
  },
  {
    id: "7abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mariany Północne",
  },
  {
    id: "7bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mauretania",
  },
  {
    id: "7cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Maroko",
  },
  {
    id: "7dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Meksyk",
  },
  {
    id: "7ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mauritius",
  },
  {
    id: "7fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mikronezja",
  },
  {
    id: "80bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mjanma",
  },
  {
    id: "81bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mołdawia",
  },
  {
    id: "82bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Monako",
  },
  {
    id: "83bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mongolia",
  },
  {
    id: "84bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Montserrat",
  },
  {
    id: "85bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Mozambik",
  },
  {
    id: "86bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Namibia",
  },
  {
    id: "87bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nepal",
  },
  {
    id: "88bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nauru",
  },
  {
    id: "89bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Niger",
  },
  {
    id: "8abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Niemcy",
  },
  {
    id: "8bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nikaragua",
  },
  {
    id: "8cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nigeria",
  },
  {
    id: "8dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Niue",
  },
  {
    id: "8ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Norfolk",
  },
  {
    id: "8fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nowa Kaledonia",
  },
  {
    id: "90bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Norwegia",
  },
  {
    id: "91bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Oman",
  },
  {
    id: "92bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Nowa Zelandia",
  },
  {
    id: "93bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Pakistan",
  },
  {
    id: "94bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Palau",
  },
  {
    id: "95bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Panama",
  },
  {
    id: "96bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Palestyna",
  },
  {
    id: "97bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Papua-Nowa Gwinea",
  },
  {
    id: "98bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Paragwaj",
  },
  {
    id: "99bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Peru",
  },
  {
    id: "9abcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Pitcairn",
  },
  {
    id: "9bbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Polinezja Francuska",
  },
  {
    id: "9cbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Polska",
  },
  {
    id: "9dbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Portugalia",
  },
  {
    id: "9ebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Portoryko",
  },
  {
    id: "9fbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Republika Środkowoafrykańska",
  },
  {
    id: "a0bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Republika Południowej Afryki",
  },
  {
    id: "a1bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Republika Zielonego Przylądka",
  },
  {
    id: "a2bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Reunion",
  },
  {
    id: "a3bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Rumunia",
  },
  {
    id: "a4bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Rosja",
  },
  {
    id: "a5bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sahara Zachodnia",
  },
  {
    id: "a6bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Rwanda",
  },
  {
    id: "a7bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint Lucia",
  },
  {
    id: "a8bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint Kitts i Nevis",
  },
  {
    id: "a9bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint-Barthélemy",
  },
  {
    id: "aabcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint Vincent i Grenadyny",
  },
  {
    id: "abbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint-Pierre i Miquelon",
  },
  {
    id: "acbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Saint-Martin",
  },
  {
    id: "adbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Samoa Amerykańskie",
  },
  {
    id: "aebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Salwador",
  },
  {
    id: "afbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "San Marino",
  },
  {
    id: "b0bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Samoa",
  },
  {
    id: "b1bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Senegal",
  },
  {
    id: "b2bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Serbia",
  },
  {
    id: "b3bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Seszele",
  },
  {
    id: "b4bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sierra Leone",
  },
  {
    id: "b5bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sint Maarten",
  },
  {
    id: "b6bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Singapur",
  },
  {
    id: "b7bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Słowacja",
  },
  {
    id: "b8bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Słowenia",
  },
  {
    id: "b9bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Somalia",
  },
  {
    id: "babcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sri Lanka",
  },
  {
    id: "bbbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Suazi",
  },
  {
    id: "bcbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Stany Zjednoczone",
  },
  {
    id: "bdbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sudan",
  },
  {
    id: "bebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Sudan Południowy",
  },
  {
    id: "bfbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Surinam",
  },
  {
    id: "c0bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Svalbard i Jan Mayen",
  },
  {
    id: "c1bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Szwajcaria",
  },
  {
    id: "c2bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Syria",
  },
  {
    id: "c3bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tadżykistan",
  },
  {
    id: "c4bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Szwecja",
  },
  {
    id: "c5bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tajwan",
  },
  {
    id: "c6bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tajlandia",
  },
  {
    id: "c7bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Timor Wschodni",
  },
  {
    id: "c8bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tanzania",
  },
  {
    id: "c9bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tokelau",
  },
  {
    id: "cabcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Togo",
  },
  {
    id: "cbbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Trynidad i Tobago",
  },
  {
    id: "ccbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tonga",
  },
  {
    id: "cdbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Turcja",
  },
  {
    id: "cebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tunezja",
  },
  {
    id: "cfbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Turkmenistan",
  },
  {
    id: "d0bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Turks i Caicos",
  },
  {
    id: "d1bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Uganda",
  },
  {
    id: "d2bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Tuvalu",
  },
  {
    id: "d3bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Urugwaj",
  },
  {
    id: "d4bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Ukraina",
  },
  {
    id: "d5bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Uzbekistan",
  },
  {
    id: "d6bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Vanuatu",
  },
  {
    id: "d7bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Watykan",
  },
  {
    id: "d8bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wallis i Futuna",
  },
  {
    id: "d9bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wenezuela",
  },
  {
    id: "dabcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Węgry",
  },
  {
    id: "dbbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wielka Brytania",
  },
  {
    id: "dcbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wietnam",
  },
  {
    id: "ddbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Włochy",
  },
  {
    id: "debcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wybrzeże Kości Słoniowej",
  },
  {
    id: "dfbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspa Bożego Narodzenia",
  },
  {
    id: "e0bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspa Bouveta",
  },
  {
    id: "e1bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
  },
  {
    id: "e2bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspa Man",
  },
  {
    id: "e3bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Cooka",
  },
  {
    id: "e4bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Alandzkie",
  },
  {
    id: "e5bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Heard i McDonalda",
  },
  {
    id: "e6bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Dziewicze Stanów Zjednoczonych",
  },
  {
    id: "e7bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Marshalla",
  },
  {
    id: "e8bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Kokosowe",
  },
  {
    id: "e9bcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Salomona",
  },
  {
    id: "eabcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Owcze",
  },
  {
    id: "ebbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Zambia",
  },
  {
    id: "ecbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Wyspy Świętego Tomasza i Książęca",
  },
  {
    id: "edbcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Zjednoczone Emiraty Arabskie",
  },
  {
    id: "eebcc77c-cb1d-eb11-a813-000d3a45bf29",
    name: "Zimbabwe",
  },
];

export const Voivodships: NamedItem[] = [
  {
    id: "a1dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "zachodniopomorskie",
  },
  {
    id: "a2dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "wielkopolskie",
  },
  {
    id: "a3dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "warmińsko-mazurskie",
  },
  {
    id: "a6dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "świętokrzyskie",
  },
  {
    id: "a7dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "śląskie",
  },
  {
    id: "a8dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "pomorskie",
  },
  {
    id: "abdc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "podlaskie",
  },
  {
    id: "acdc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "podkarpackie",
  },
  {
    id: "addc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "opolskie",
  },
  {
    id: "aedc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "mazowieckie",
  },
  {
    id: "afdc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "małopolskie",
  },
  {
    id: "b0dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "łódzkie",
  },
  {
    id: "b1dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "lubuskie",
  },
  {
    id: "b2dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "lubelskie",
  },
  {
    id: "b3dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "kujawsko-pomorskie",
  },
  {
    id: "b4dc2fc5-cc1d-eb11-a813-000d3a45bf29",
    name: "dolnośląskie",
  },
];

export const WarsawDistricts: NamedItem[] = [
  {
    id: "704590000",
    name: "Bemowo",
  },
  {
    id: "704590001",
    name: "Białołęka",
  },
  {
    id: "704590002",
    name: "Bielany",
  },
  {
    id: "704590003",
    name: "Centrum",
  },
  {
    id: "704590004",
    name: "Mokotów",
  },
  {
    id: "704590005",
    name: "Ochota",
  },
  {
    id: "704590006",
    name: "Praga-Południe",
  },
  {
    id: "704590007",
    name: "Praga-Północ",
  },
  {
    id: "704590008",
    name: "Rembertów",
  },
  {
    id: "704590009",
    name: "Śródmieście",
  },
  {
    id: "704590010",
    name: "Targówek",
  },
  {
    id: "704590011",
    name: "Ursus",
  },
  {
    id: "704590012",
    name: "Ursynów",
  },
  {
    id: "704590013",
    name: "Wawer",
  },
  {
    id: "704590014",
    name: "Wilanów",
  },
  {
    id: "704590015",
    name: "Włochy",
  },
  {
    id: "704590016",
    name: "Wola",
  },
  {
    id: "704590017",
    name: "Żoliborz",
  },
];

export const PolandId: string = "9cbcc77c-cb1d-eb11-a813-000d3a45bf29";
