import React, {Component, ReactElement} from "react";

export interface DataEntryProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  errors: {[key: string]: string | undefined};
  onUpdate: (errors: {[key: string]: string | undefined}) => void;
  onChange?: (event: React.FormEvent<HTMLElement>) => void;
}

export default class DataEntry extends Component<DataEntryProps> {
  onChange(event: React.FormEvent<HTMLElement>): void {
    if (this.props.onChange) {
      this.props.onChange(event);
    }

    const target = event.target as HTMLElement;
    const targetKey = target.getAttribute("id") || target.getAttribute("name");

    const {errors} = this.props;
    const errorsClone = {...errors};
    if (targetKey && targetKey in errors) {
      delete errorsClone[targetKey];
      this.props.onUpdate(errorsClone);
    }
  }

  render(): ReactElement {
    return (
      <div
        id={this.props.id}
        className={this.props.className}
        onChange={this.onChange.bind(this)}
      >
        {this.props.children}
      </div>
    );
  }
}
