import React, {Component, ReactElement} from "react";
import NamedSelect, {NamedItem} from "./select-named";

export interface DateSelectProps {
  value?: Date;
  minYear?: number;
  maxYear?: number;
  onDateSelect?: (day: number, month: number, year: number) => void;
}

export interface DateSelectState {
  year?: number;
  month?: number;
  day?: number;

  days: NamedItem[];
  months: NamedItem[];
  years: NamedItem[];
}

export default class DateSelect extends Component<
  DateSelectProps,
  DateSelectState
> {
  constructor(props: DateSelectProps) {
    super(props);
    const {value} = props;

    this.state = {
      day: value?.getDate(),
      year: value?.getFullYear(),
      month: value !== undefined ? value.getMonth() + 1 : undefined,
      days: this.getDatesArray(),
      months: this.getMonthsArray(),
      years: this.getYearsArray(props.minYear, props.maxYear),
    };
  }

  // TODO: handle props updates

  getMonthsArray(): NamedItem[] {
    const from = 1,
      to = 12;
    const a = [];
    for (let i = from; i <= to; i++) {
      a.push({
        id: i.toString(),
        name: i.toString(), // TODO: names I.t("month." + i),
      });
    }
    return a;
  }

  getDatesArray(max: number = 31): NamedItem[] {
    const from = 1,
      to = max;
    const a = [];
    for (let i = from; i <= to; i++) {
      a.push({
        id: i.toString(),
        name: i.toString(),
      });
    }
    return a;
  }

  getYearsArray(minYear?: number, maxYear?: number): NamedItem[] {
    const now = new Date();
    const currentYear = now.getFullYear();

    if (minYear === undefined) {
      minYear = currentYear - 100;
    }
    if (maxYear === undefined) {
      maxYear = currentYear - 14;
    }

    const a = [];
    for (let i = maxYear; i >= minYear; i--) {
      a.push({
        id: i.toString(),
        name: i.toString(),
      });
    }
    return a;
  }

  checkDateSelected(): void {
    setTimeout(() => {
      const {day, month, year} = this.state;

      if (day === undefined || month === undefined || year == undefined) {
        return;
      }

      if (this.props.onDateSelect) this.props.onDateSelect(day, month, year);
    }, 0);
  }

  onDaySelect(value: NamedItem | null): void {
    this.setState({day: value === null ? undefined : parseInt(value.id)});
    this.checkDateSelected();
  }

  onMonthSelect(value: NamedItem | null): void {
    this.setState({month: value === null ? undefined : parseInt(value.id)});
    this.checkDays();
  }

  onYearSelect(value: NamedItem | null): void {
    this.setState({year: value === null ? undefined : parseInt(value.id)});
    this.checkDays();
  }

  checkDays(): void {
    // on month or year select, check the number of days in the month
    setTimeout(() => {
      this.ensureDate();
      this.checkDateSelected();
    }, 0);
  }

  daysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  ensureDate(): void {
    const {day, month, year} = this.state;
    if (month === undefined) {
      return;
    }

    const daysInMonth = this.daysInMonth(
      year || new Date().getFullYear(),
      month
    );

    if (day !== undefined && day > daysInMonth) {
      this.setState({
        day: daysInMonth,
        days: this.getDatesArray(daysInMonth),
      });
    } else {
      this.setState({
        days: this.getDatesArray(daysInMonth),
      });
    }
  }

  render(): ReactElement {
    const {day, month, year, days, months, years} = this.state;
    return (
      <div className="date-select">
        <NamedSelect
          placeholder="DD"
          items={days}
          value={day ? day.toString() : undefined}
          onSelect={this.onDaySelect.bind(this)}
          disallowEmpty
        />
        <NamedSelect
          placeholder="MM"
          items={months}
          onSelect={this.onMonthSelect.bind(this)}
          disallowEmpty
          value={month ? month.toString() : undefined}
        />
        <NamedSelect
          placeholder="RRRR"
          items={years}
          onSelect={this.onYearSelect.bind(this)}
          disallowEmpty
          value={year ? year.toString() : undefined}
        />
      </div>
    );
  }
}
