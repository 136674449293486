import Head from "./common/head";
import MainBanner from "./common/main-banner";
import React, {ReactElement} from "react";
import Survey from "./survey/survey";
import {Services} from "../service/services";

export default function Home(): ReactElement {
  return (
    <div>
      <Head title="Fundacja Bullerbyn" />
      <MainBanner>
        <Survey services={Services} />
      </MainBanner>
    </div>
  );
}
