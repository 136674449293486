import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import {
  AccomodationTerm,
  AccomodationType,
  getAccomodationTypesOptionsByBirthYear,
} from "../../service/domain/accomodations";
import {Voucher, VouchersApi} from "../../service/domain/vouchers";
import {IServices} from "../../service/services";
import DataEntry from "../common/forms/dataentry";
import Field from "../common/forms/field";
import {changeHandler} from "../common/forms/forms";
import Radios from "../common/forms/radios";
import {NamedItem} from "../common/forms/select-named";
import {
  Required,
  ValidationContext,
  ValidationResult,
  ValidationRule,
  ValidationSchema,
} from "../common/forms/validation";
import AccomodationTermSelect from "./accomodation-terms";

export interface AccomodationInfo {
  accomodationType?: AccomodationType;
  accomodationTerm?: AccomodationTerm;
  alternativeTerms?: string;
  status?: string;
  voucher: string;
  voucherItem: Voucher | null;
}

export interface AccomodationInfoPageProps {
  childBirthYear: number;
  childGender: string;
  values?: AccomodationInfo;
  services: IServices;
  onGoBack(values: AccomodationInfo): void;
  onGoNext(values: AccomodationInfo): void;
}

interface AccomodationInfoPageState
  extends AccomodationInfo,
    ValidationContext {
  loadingTerms: boolean;
}

interface StatusOption extends NamedItem {
  price: number;
}

class VoucherValidator extends ValidationRule<AccomodationInfo, string> {
  constructor(
    api: VouchersApi,
    onVoucherFetched: (item: Voucher | null) => void
  ) {
    super(
      "voucher",
      (
        context: AccomodationInfo,
        value: string
      ): Promise<ValidationResult> => {
        // TODO: shot the API, check value, return error
        return new Promise((resolve, reject) => {
          if (!value) {
            resolve();
            return;
          }

          api.getVoucherByCode(value).then(
            (voucher: Voucher | null) => {
              onVoucherFetched(voucher);
              if (!voucher) {
                // voucher not found
                resolve({
                  error: "Nie znaleziono kuponu",
                });
                return;
              }

              if (voucher.isUsed) {
                // voucher already used
                resolve({
                  error: "Kupon został już wykorzystany",
                });
                return;
              }

              resolve();
            },
            () => {
              resolve({
                error: "Wystąpił błąd techniczny podczas weryfikacji kuponu",
              });
            }
          );
        });
      }
    );
  }
}

export default class AccomodationInfoPage extends Component<
  AccomodationInfoPageProps,
  AccomodationInfoPageState
> {
  schema: ValidationSchema<AccomodationInfo>;

  constructor(props: AccomodationInfoPageProps) {
    super(props);

    this.state = {
      errors: {},
      loadingTerms: false,
      accomodationTerm: undefined,
      voucher: "",
      voucherItem: null,
      ...props.values,
    };

    this.schema = new ValidationSchema<AccomodationInfo>(
      {
        accomodationType: [
          new Required({
            errorMessage: "Proszę wybrać opcję",
          }),
        ],
        accomodationTerm: [
          new Required({
            errorMessage: "Proszę wybrać opcję",
          }),
        ],
        alternativeTerms: [],
        status: [
          new Required({
            errorMessage: "Proszę wybrać opcję",
          }),
        ],
        voucher: [
          new VoucherValidator(
            props.services.vouchers,
            (voucher: Voucher | null) => {
              this.setState({voucherItem: voucher});
            }
          ),
        ],
        voucherItem: [],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  prev(): void {
    this.setState({
      accomodationTerm: undefined,
    });
    this.props.onGoBack(this.state);
  }

  async validate(): Promise<boolean> {
    const pageValidation = await this.schema.validate(this.state);
    return !pageValidation.hasErrors;
  }

  getValues(): AccomodationInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onAccomodationTypeSelect(value: NamedItem) {
    this.setState({
      accomodationType: value.id as AccomodationType,
      accomodationTerm: undefined,
    });
  }

  onAccomodationTermSelect(value: AccomodationTerm) {
    this.setState({
      accomodationTerm: value,
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  priceLabel(price: number): string {
    return `${(Math.round(price * 100) / 100).toFixed(2)} PLN`;
  }

  getStatusOptions(): StatusOption[] {
    // TODO: use base price
    const {accomodationTerm} = this.state;

    if (accomodationTerm === undefined) {
      return [];
    }

    const price = accomodationTerm?.price;
    return [
      {
        id: "first-time",
        name: "pierwszy udział w projekcie Fundacji Bullerbyn",
        price: price,
      },
      {
        id: "another-time",
        name: "kolejny udział w projekcie Fundacji Bullerbyn",
        price: price - 100,
      },
    ];
  }

  render(): ReactElement {
    const {childBirthYear, childGender, services} = this.props;
    const {
      errors,
      accomodationType,
      accomodationTerm,
      alternativeTerms,
      status,
      voucher,
    } = this.state;
    const accomodationTypes = getAccomodationTypesOptionsByBirthYear(
      childBirthYear
    );
    const canSubmit = accomodationTerm && status;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <div>
          <h2>Turnus i zakwaterowanie</h2>
          <dl>
            <dt>Zakwaterowanie</dt>
            <dd>
              <Field error={errors.accomodationType}>
                <Radios
                  items={accomodationTypes}
                  name="accomodationType"
                  onSelect={this.onAccomodationTypeSelect.bind(this)}
                  value={accomodationType}
                />
              </Field>
            </dd>
            {accomodationType !== undefined && (
              <React.Fragment>
                <dt>Turnus</dt>
                <dd>
                  <Field error={errors.accomodationTerm}>
                    <AccomodationTermSelect
                      type={accomodationType}
                      childBirthYear={childBirthYear}
                      childGender={childGender}
                      services={services}
                      onSelect={this.onAccomodationTermSelect.bind(this)}
                      value={accomodationTerm}
                    />
                  </Field>
                </dd>
                <dt>
                  <label htmlFor="alternativeTerms">
                    Podaj termin/turnus alternatywny, jeżeli w wybranym
                    terminie nie będzie miejsc.
                  </label>
                </dt>
                <dd>
                  <TextField
                    required
                    id="alternativeTerms"
                    onChange={changeHandler.bind(this)}
                    multiline
                    rows={6}
                    value={alternativeTerms}
                  />

                  <i className="terms-link">
                    Jeżeli nie ma już miejsca w interesującym Cię turnusie to
                    wpisz dziecko na listę rezerwową.
                    <a
                      href="https://forms.office.com/e/AbaAt6eWEB"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link do formularza rezerwowego (otwiera się na nowej
                      stronie)
                    </a>
                  </i>
                </dd>
              </React.Fragment>
            )}
          </dl>
          {accomodationTerm !== undefined && (
            <React.Fragment>
              <h2>Opłaty</h2>
              <dl>
                <dt>Status podopiecznego</dt>
                <dd>
                  <Field error={errors.status}>
                    <RadioGroup
                      row
                      aria-label="status"
                      name="status"
                      value={status || ""}
                      onChange={changeHandler.bind(this)}
                    >
                      {this.getStatusOptions().map((item) => {
                        return (
                          <div key={item.id}>
                            <FormControlLabel
                              value={item.id}
                              control={<Radio />}
                              label={item.name}
                            />
                            <span className="price">
                              cena: {this.priceLabel(item.price)}
                            </span>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </Field>
                </dd>
              </dl>
            </React.Fragment>
          )}
        </div>
        <div className="buttons-area space-between">
          <Button onClick={() => this.prev()}>Wstecz</Button>
          <Button
            onClick={() => this.next()}
            variant="contained"
            color="primary"
            disabled={!canSubmit}
          >
            Zatwierdź
          </Button>
        </div>
      </DataEntry>
    );
  }
}
