/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {ApplicationError, NotFoundError} from "./errors";

const JSON_ContentType = "application/json; charset=utf-8";

// Exposes a dictionary that can be used by external code to include
// custom headers in a central place.
export const CustomHeaders: {[key: string]: string} = {};

// NB: @typescript-eslint/explicit-module-boundary-types is disabled
// because JSON.stringify and JSON.parse themselves are typed with `any`.
// There is no need to be more royal than the king and more saint than the pope
// Besides, we cannot assume that the server will return a body known to the
// client.

async function tryParseBodyAsJSON(response: Response): Promise<any> {
  const contentType = response.headers.get("content-type");

  if (contentType !== null && contentType.indexOf("json") > -1) {
    return await response.json();
  }

  return await response.text();
}

interface AppFetchOptions {
  notFoundIsFine?: boolean;
}

/**
 * Wrapper around fetch API, with common logic to handle application errors
 * and response bodies.
 *
 * If the server returns 401 Unauthorized, this method tries once to obtain new
 * tokens silently. If that succeeds, the application flow continues
 * transparently, by repeating the original web request with a new token.
 */
async function appFetch<T>(
  input: RequestInfo,
  init?: RequestInit,
  options: AppFetchOptions = {}
): Promise<T> {
  // extend init properties with an access token
  if (init === undefined) {
    init = {
      headers: Object.assign({}, CustomHeaders),
    };
  } else {
    init.headers = Object.assign({}, init.headers, CustomHeaders);
  }

  const response = await fetch(input, init);

  const data = await tryParseBodyAsJSON(response);

  if (response.status === 404) {
    if (options.notFoundIsFine) {
      return null as any;
    }
    throw new NotFoundError();
  }

  if (response.status >= 400) {
    throw new ApplicationError(
      "Response status does not indicate success",
      response.status,
      data
    );
  }

  return data as T;
}

export async function get<T>(url: string, headers?: HeadersInit): Promise<T> {
  return await appFetch(url, {
    method: "GET",
    headers,
  });
}

export async function getOptional<T>(
  url: string,
  headers?: HeadersInit
): Promise<T | null> {
  return await appFetch(
    url,
    {
      method: "GET",
      headers,
    },
    {notFoundIsFine: true}
  );
}

export async function post<T>(url: string, data: any = null): Promise<T> {
  if (!data) {
    return await appFetch(url, {
      method: "POST",
    });
  }

  return await appFetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": JSON_ContentType,
    },
  });
}

export async function patch<T>(url: string, data: any): Promise<T> {
  return await appFetch(url, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": JSON_ContentType,
    },
  });
}

export async function put<T>(url: string, data: any): Promise<T> {
  return await appFetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": JSON_ContentType,
    },
  });
}

export async function del<T>(url: string, data: any = null): Promise<T> {
  if (!data) {
    return await appFetch(url, {
      method: "DELETE",
    });
  }

  return await appFetch(url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": JSON_ContentType,
    },
  });
}
