import {Button} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import WizardPage from "./page";

export interface WizardProps {
  pages: WizardPage[]; // React.ReactNode[];
}

export interface WizardState {
  pageNumber: number;
}

export default class Wizard extends Component<WizardProps, WizardState> {
  constructor(props: WizardProps) {
    super(props);

    this.state = {
      pageNumber: 0,
    };
  }

  back(): void {
    const {pageNumber} = this.state;
    this.setState({pageNumber: pageNumber - 1});
  }

  next(): void {
    const {pageNumber} = this.state;
    this.setState({pageNumber: pageNumber + 1});
  }

  render(): ReactElement {
    const {pages} = this.props;
    const {pageNumber} = this.state;

    const page = pages[pageNumber];

    return (
      <div>
        {page}
        <div className="buttons-area">
          {pageNumber > 0 && <Button onClick={() => this.back()}>Back</Button>}
          <Button onClick={() => this.next()}>Next</Button>
        </div>
      </div>
    );
  }
}
