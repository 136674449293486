export interface Term {
  id: string;
  name: string;
}

export enum AccomodationType {
  home = "home",
  swedishCottage = "swedishCottage",
  teepee = "teepee",
  twoweekteepee = "twoweekteepee"
}

export interface AccomodationTypeOption {
  id: string;
  name: string;
}

// TODO: handle localization somewhere else
const polishNames: {[key in AccomodationType]: string} = {
  home: "dom główny ",
  swedishCottage: "domek szwedzki",
  teepee: "tipi",
  twoweekteepee: "tipi dwutygodniowe"
};

export function getAccomodationTypesByBirthYear(
  birthYear: number
): AccomodationType[] {
  switch (birthYear) {
    case 2016:
      return [
        AccomodationType.home,
        AccomodationType.teepee,
        AccomodationType.swedishCottage
      ];
    case 2015:
      return [
        AccomodationType.home,
        AccomodationType.teepee,
        AccomodationType.swedishCottage
      ];
    case 2014:
      return [
        AccomodationType.swedishCottage,
        AccomodationType.teepee,
        AccomodationType.twoweekteepee
      ];
      case 2013:
      return [
        AccomodationType.teepee, 
        AccomodationType.twoweekteepee
      ];
      case 2012:
      return [
        AccomodationType.teepee, 
        AccomodationType.twoweekteepee
      ];
      
    default:
      return [AccomodationType.teepee];
  }
}

export function getAccomodationTypesOptionsByBirthYear(
  birthYear: number
): AccomodationTypeOption[] {
  return getAccomodationTypesByBirthYear(birthYear).map(
    (type: AccomodationType) => {
      return {
        id: type,
        name: polishNames[type],
      };
    }
  );
}

export interface AccomodationTerm {
  id: string;
  name: string;
  price: number;
  freeSpotsMale: number;
  freeSpotsFemale: number;
}

export interface AccomodationsApi {
  getAvailableTerms(accomodationType: string): Promise<AccomodationTerm[]>;
}
