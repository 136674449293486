import React, {Component, ReactElement} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {getRoutes} from "./routes";

interface LayoutProps {
  foo?: boolean;
}

interface LayoutState {
  foo?: boolean;
}

export default class Layout extends Component<LayoutProps, LayoutState> {
  render(): ReactElement {
    const routes = getRoutes();

    return (
      <Router>
        <div id="content-area">
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                {route.main}
              </Route>
            ))}
          </Switch>
        </div>
      </Router>
    );
  }
}
