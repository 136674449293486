import React, {Component, ReactElement} from "react";

interface BannerProps {
  children?: React.ReactNode;
}

export default class MainBanner extends Component<BannerProps> {
  render(): ReactElement {
    return <div id="main-banner">{this.props.children}</div>;
  }
}
