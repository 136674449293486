import React, {Component, ReactElement} from "react";

export interface WizardPageProps {
  title: string;
  children: React.ReactNode;
  validate?: () => Promise<boolean>;
}

export interface WizardPageState {
  complete: boolean;
}

export default class WizardPage extends Component<
  WizardPageProps,
  WizardPageState
> {
  constructor(props: WizardPageProps) {
    super(props);

    this.state = {
      complete: false,
    };
  }

  async validate(): Promise<void> {
    if (this.props.validate) this.props.validate();
  }

  render(): ReactElement {
    return (
      <div>
        <h2>{this.props.title}</h2>
        {this.props.children}
      </div>
    );
  }
}
