import {BaseURL} from ".";
import {getOptional} from "../../common/fetch";
import {Child, PersonsApi} from "../domain/persons";

export default class CDSPersonsApi implements PersonsApi {
  async getChild(
    documentNumber: string,
    firstName: string,
    lastName: string
  ): Promise<Child | null> {
    const query =
      `?documentId=${documentNumber}` +
      `&firstName=${firstName}` +
      `&lastName=${lastName}`;
    return getOptional<Child | null>(
      `${BaseURL}/api/participants/participant${query}`
    );
  }
}
