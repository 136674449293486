import {createMuiTheme, Theme} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

// primary #56073e 94146d?
// secondary #398ec1 177f63?

export default function getMuiTheme(): Theme {
  return createMuiTheme({
    palette: {
      primary: {
        main: "#175080",
      },
      secondary: {
        main: "#5a4700",
      },
      error: {
        main: red.A400,
      },
      background: {
        default: "#fff",
      },
    },
    props: {
      MuiButton: {
        variant: "outlined",
        color: "primary",
      },
      MuiTextField: {
        variant: "outlined",
        color: "primary",
      },
      MuiCheckbox: {
        color: "primary",
      },
      MuiRadio: {
        color: "primary",
      },
    },
  });
}
