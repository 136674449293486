import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import {NamedItem} from "./abc";

export interface RadiosProps<T extends NamedItem> {
  name: string;
  items: T[];
  onSelect: (item: T) => void;
  row?: boolean;
  value?: string;
}

export interface RadiosState {
  value: string;
}

export default class Radios<T extends NamedItem> extends Component<
  RadiosProps<T>,
  RadiosState
> {
  constructor(props: RadiosProps<T>) {
    super(props);

    this.state = {
      value: props.value || "",
    };
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      value: event.target.value,
    });

    const item = this.props.items.find(
      (item) => item.id === event.target.value
    );
    if (item) this.props.onSelect(item);
  }

  render(): ReactElement {
    const {name, items, row} = this.props;
    const {value} = this.state;
    return (
      <RadioGroup
        row={row}
        aria-label={name}
        name={name}
        value={value}
        onChange={this.onChange.bind(this)}
      >
        {items.map((item) => {
          return (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              label={item.name}
            />
          );
        })}
      </RadioGroup>
    );
  }
}
