import getMuiTheme from "./components/theme";
import Layout from "./components/layout";
import React from "react";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import "./service/monitoring";
import "./styles/global.scss";

function App(): React.ReactElement {
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <CssBaseline />
      <Layout />
    </ThemeProvider>
  );
}

export default App;
