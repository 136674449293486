import {BaseURL} from ".";
import {get} from "../../common/fetch";
import {AccomodationTerm, AccomodationsApi} from "../domain/accomodations";

// TODO: improve this, fetch the list using the API
// /api/accomodation-types
const ids: {[key: string]: string} = {
  home: "3010f019-4641-ec11-8c62-6045bd89911e",
  swedishCottage: "3fec14fc-4641-ec11-8c62-6045bd89911e",
  teepee: "23cb1e7b-4841-ec11-8c62-6045bd89911e",
  twoweekteepee: "9620d3a0-4841-ec11-8c62-6045bd89911e",
};

export default class CDSAccomodationsApi implements AccomodationsApi {
  async getAvailableTerms(
    accomodationType: string
  ): Promise<AccomodationTerm[]> {
    return get<AccomodationTerm[]>(
      `${BaseURL}/api/accomodations/${ids[accomodationType]}`
    );
  }
}
