import {ReactElement} from "react";
import Home from "./home";
import Test from "./test";

interface Route {
  path: string;
  exact: boolean;
  main: () => ReactElement;
}

export function getRoutes(): Route[] {
  return [
    {
      path: "/",
      exact: true,
      main: Home,
    },
    {
      path: "/test",
      exact: true,
      main: Test,
    },
  ];
}
