import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {ChangeEvent, Component, ReactElement} from "react";
import {
  AccomodationTerm,
  AccomodationType,
} from "../../service/domain/accomodations";
import {IServices} from "../../service/services";
import {changeHandler} from "../common/forms/forms";
import Loader from "../common/loader";

export interface AccomodationTermsProps {
  type: AccomodationType;
  childGender: string;
  childBirthYear: number;
  services: IServices;
  onSelect: (item: AccomodationTerm) => void;
  value?: AccomodationTerm;
}

export interface AccomodationTermsState {
  loading: boolean;
  terms: AccomodationTerm[];
  accomodationTerm: string | undefined;
}

export default class AccomodationTermSelect extends Component<
  AccomodationTermsProps,
  AccomodationTermsState
> {
  constructor(props: AccomodationTermsProps) {
    super(props);

    this.state = {
      loading: true,
      terms: [],
      accomodationTerm: props.value?.id || "",
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(props: AccomodationTermsProps) {
    const {type, childBirthYear, childGender} = this.props;

    if (
      props.type !== type ||
      props.childBirthYear !== childBirthYear ||
      props.childGender !== childGender
    ) {
      this.load();
    }
  }

  async load(): Promise<void> {
    this.setState({
      loading: true,
    });

    const {type} = this.props;

    const terms = await this.props.services.accomodations.getAvailableTerms(
      type
    );

    terms.sort((a: AccomodationTerm, b: AccomodationTerm) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    this.setState({
      loading: false,
      terms,
    });
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    // user's interaction
    changeHandler.call(this, event);

    const item = this.state.terms.find(
      (item) => item.id === event.target.value
    );

    if (item) {
      this.props.onSelect(item);
    }
  }

  getAvailablePlacesForGender(item: AccomodationTerm): number {
    const {childGender} = this.props;

    if (childGender === "M") {
      return item.freeSpotsMale;
    }

    return item.freeSpotsFemale;
  }

  render(): ReactElement {
    const {loading, terms, accomodationTerm} = this.state;

    if (loading) {
      return <Loader className="mini" />;
    }

    if (!terms.length) {
      // This should never happen!
      return (
        <div className="term-selection">
          <span>Brak miejsc</span>
        </div>
      );
    }

    const name = "accomodationTerm";

    return (
      <div className="term-selection">
        <RadioGroup
          row
          aria-label={name}
          name={name}
          value={accomodationTerm}
          onChange={this.onChange.bind(this)}
        >
          {terms.map((item) => {
            const freeSpots = this.getAvailablePlacesForGender(item);
            return (
              <div key={item.id}>
                <FormControlLabel
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                  disabled={freeSpots < 1}
                />
                {freeSpots > 0 && (
                  <span className="free-spots">
                    Wolne miejsca: {freeSpots}
                  </span>
                )}
                {freeSpots < 1 && (
                  <span className="no-spots">Brak miejsc</span>
                )}
              </div>
            );
          })}
        </RadioGroup>
      </div>
    );
  }
}
