import {BaseURL} from ".";
import {post} from "../../common/fetch";
import {
  ApplicationInput,
  ApplicationOutput,
  ApplicationsApi,
} from "../domain/applications";

export default class CDSApplicationsApi implements ApplicationsApi {
  async submit(applicationData: ApplicationInput): Promise<ApplicationOutput> {
    console.log(JSON.stringify(applicationData, null, 2));
    return post<ApplicationOutput>(
      `${BaseURL}/api/application`,
      applicationData
    );
  }
}
