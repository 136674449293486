import DateSelect from "../common/forms/date-select";
import Radios from "../common/forms/radios";
import React, {Component, ReactElement} from "react";
import {Gender} from "../../service/domain/persons";
import {Button, TextField} from "@material-ui/core";
import {changeHandler, removeError} from "../common/forms/forms";
import {
  Pattern,
  Required,
  ValidationContext,
  ValidationSchema,
} from "../common/forms/validation";
import Field from "../common/forms/field";
import DataEntry from "../common/forms/dataentry";
import {IServices} from "../../service/services";
import Alert, {AlertProps, AlertSeverity} from "../common/alert";
import Loader from "../common/loader";

export interface ChildInfo {
  childFirstName: string;
  childLastName: string;
  childPesel: string;
  childClass: string;
  childBirthDate: Date | null;
  childGender: string;
}

interface ChildInfoPageProps {
  values?: ChildInfo;
  onGoNext(values: ChildInfo): void;
  services: IServices;
}

interface ChildInfoPageState extends ChildInfo, ValidationContext {
  loading: boolean;
  problem?: AlertProps;
}

const Genders: Gender[] = [
  {
    id: "M",
    name: "Chłopiec",
  },
  {
    id: "F",
    name: "Dziewczynka",
  },
];

export default class ChildInfoPage extends Component<
  ChildInfoPageProps,
  ChildInfoPageState
> {
  schema: ValidationSchema<ChildInfo>;

  constructor(props: ChildInfoPageProps) {
    super(props);

    this.state = {
      errors: {},
      loading: false,
      childFirstName: "",
      childLastName: "",
      childPesel: "",
      childClass: "",
      childBirthDate: null,
      childGender: "",
      ...props.values,
    };

    this.schema = new ValidationSchema<ChildInfo>(
      {
        childFirstName: [new Required()],
        childLastName: [new Required()],
        childGender: [new Required()],
        childPesel: [new Required()],
        childClass: [new Required(), new Pattern(/^\d+$/)],
        childBirthDate: [
          new Required({errorMessage: "Wybierz datę urodzenia"}),
        ],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  onBirthDateSelected(day: number, month: number, year: number): void {
    this.setState({
      childBirthDate: new Date(year, month - 1, day),
    });
    removeError(this, "childBirthDate");
  }

  onGenderSelect(childGender: Gender): void {
    this.setState({
      childGender: childGender.id,
    });
  }

  async validate(): Promise<boolean> {
    const pageValidation = await this.schema.validate(this.state);

    if (pageValidation.hasErrors) {
      return false;
    }

    this.setState({
      loading: true,
    });

    let noConflict: boolean = false;
    try {
      // validate pesel
      noConflict = await this.validateNotAlreadyRegistered();
    } catch (error) {
      this.setState({
        loading: false,
        problem: {
          title: "Błąd techniczny",
          message: `Wystąpił nieoczekiwany błąd. Skontaktuj się z
          administratorami serwisu, jeśli problem będzie się powtarzał.`,
          severity: AlertSeverity.error,
        },
      });

      return false;
    }

    if (!noConflict) {
      // child with given input is already registered
      this.setState({
        loading: false,
        problem: {
          title: "Dziecko z podanymi danymi jest już zarejestrowane.",
          message: `Zrealizujemy każdą prośbę, rejestracja dziecka więcej niż
            raz nie zwiększa możliwości bycia wybranym!`,
          severity: AlertSeverity.warning,
        },
      });
      return false;
    } else {
      this.setState({
        loading: false,
        problem: undefined,
      });
    }

    return true;
  }

  async validateNotAlreadyRegistered(): Promise<boolean> {
    const {services} = this.props;
    const {childFirstName, childLastName, childPesel} = this.state;
    const {persons} = services;

    if (
      childPesel === undefined ||
      childFirstName === undefined ||
      childLastName === undefined
    )
      throw new Error("Missing context");

    // is there already a child with the same input?
    const child = await persons.getChild(
      childPesel,
      childFirstName,
      childLastName
    );

    if (child === null) {
      return true;
    }

    return false;
  }

  getValues(): ChildInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  render(): ReactElement {
    const {
      errors,
      childGender,
      childFirstName,
      childLastName,
      childPesel,
      childBirthDate,
      childClass,
      problem,
      loading,
    } = this.state;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <h2>Dane dziecka</h2>
        <dl>
          <dt className="ui-required">Płeć</dt>
          <dd>
            <Field error={errors.childGender}>
              <Radios
                row
                items={Genders}
                name="childGender"
                onSelect={this.onGenderSelect.bind(this)}
                value={childGender}
              />
            </Field>
          </dd>
          <dt className="ui-required">
            <label htmlFor="childFirstName">Imię</label>
          </dt>
          <dd>
            <TextField
              required
              error={!!errors.childFirstName}
              helperText={errors.childFirstName}
              id="childFirstName"
              value={childFirstName}
              onChange={changeHandler.bind(this)}
            />
          </dd>
          <dt className="ui-required">
            <label htmlFor="childLastName">Nazwisko</label>
          </dt>
          <dd>
            <TextField
              required
              id="childLastName"
              error={!!errors.childLastName}
              helperText={errors.childLastName}
              onChange={changeHandler.bind(this)}
              value={childLastName}
            />
          </dd>
          <dt className="ui-required">
            <label htmlFor="childBirthDate">Data urodzenia</label>
          </dt>
          <dd>
            <Field error={errors.childBirthDate}>
              <DateSelect
                value={childBirthDate || undefined}
                minYear={2012}
                maxYear={2016}
                onDateSelect={this.onBirthDateSelected.bind(this)}
              />
            </Field>
          </dd>
          <dt className="ui-required">
            <label htmlFor="childPesel">Pesel</label>
          </dt>
          <dd>
            <TextField
              required
              id="childPesel"
              error={!!errors.childPesel}
              helperText={errors.childPesel}
              onChange={changeHandler.bind(this)}
              value={childPesel}
            />
          </dd>
          <dt className="ui-required">
            <label htmlFor="childClass">Klasa</label>
          </dt>
          <dd>
            <TextField
              required
              id="childClass"
              error={!!errors.childClass}
              helperText={errors.childClass}
              onChange={changeHandler.bind(this)}
              value={childClass}
            />
          </dd>
        </dl>
        {loading && <Loader className="overlay" />}
        {problem && <Alert {...problem} />}
        <div className="buttons-area right">
          <Button onClick={() => this.next()}>Dalej</Button>
        </div>
      </DataEntry>
    );
  }
}
