import {TextField} from "@material-ui/core";
import React, {ChangeEvent, Component, ReactElement} from "react";
import DataEntry from "../common/forms/dataentry";
import Field from "../common/forms/field";
import {changeHandler, removeError} from "../common/forms/forms";
import NamedSelect, {NamedItem} from "../common/forms/select-named";
import {
  Required,
  SchemaValidationResult,
  ValidationContext,
  ValidationSchema,
} from "../common/forms/validation";
import {
  Countries,
  PolandId,
  Voivodships,
  WarsawDistricts,
} from "./dictionaries";

export interface AddressData {
  country: string;
  city: string;
  zipCode: string;
  street: string;
  voivodeship: string;
  community: string;
}

export interface AddressProps {
  value?: AddressData;
  onAddresComplete: (address: AddressData) => void;
}

export interface AddressState extends AddressData, ValidationContext {
  showCommunity: boolean;
  showVoivodeships: boolean;
}

const countryOptions = Countries;

const communityOptions = WarsawDistricts;

const voivodeshipsOptions = Voivodships;

// Specific control for the survey, not reusable in other applications

export default class Address extends Component<AddressProps, AddressState> {
  schema: ValidationSchema<AddressData>;

  constructor(props: AddressProps) {
    super(props);

    const {value} = props;
    this.state = {
      country: value?.country || PolandId,
      city: value?.city || "",
      zipCode: value?.zipCode || "",
      street: value?.street || "",

      // only for Poland:
      voivodeship: value?.voivodeship || "",
      showVoivodeships: true,

      // only for Warsaw:
      community: value?.community || "",
      showCommunity: false,
      errors: {},
    };

    this.schema = new ValidationSchema<AddressData>(
      {
        country: [new Required()],
        city: [new Required()],
        zipCode: [new Required()],
        street: [new Required()],
        voivodeship: [
          new Required({
            condition: () => {
              return this.state.country === PolandId;
            },
          }),
        ],
        community: [
          new Required({
            condition: () => {
              return /warszawa/i.test(this.state.city);
            },
          }),
        ],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  validate(): Promise<SchemaValidationResult<AddressData>> {
    return this.schema.validate(this.state);
  }

  onCountrySelect(item: NamedItem | null): void {
    this.setState({
      country: item ? item.id : "",
    });
    if (!item) return;

    if (item.id === PolandId) {
      this.setState({
        showVoivodeships: true,
      });
    } else {
      this.setState({
        voivodeship: "",
        showVoivodeships: false,
      });
    }
  }

  onCommunitySelect(item: NamedItem | null): void {
    this.setState({
      community: item ? item.id : "",
    });
    removeError(this, "community");
  }

  onVoivodshipSelect(item: NamedItem | null): void {
    this.setState({
      voivodeship: item ? item.id : "",
    });
    removeError(this, "voivodeship");
  }

  onCityChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ) {
    changeHandler.call(this, event);

    const value = event.target.value as string;
    if (/warszawa/i.test(value)) {
      this.setState({
        showCommunity: true,
      });
    } else {
      this.setState({
        community: "",
        showCommunity: false,
      });
    }
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  onChange(): void {
    setTimeout(() => {
      this.schema.validate(this.state, true).then((results) => {
        if (!results.hasErrors) {
          const {
            country,
            city,
            zipCode,
            street,
            voivodeship,
            community,
          } = this.state;
          this.props.onAddresComplete({
            country,
            city,
            zipCode,
            street,
            voivodeship,
            community,
          });
        }
      });
    });
  }

  render(): ReactElement {
    const {
      errors,
      showCommunity,
      showVoivodeships,
      country,
      city,
      street,
      voivodeship,
      community,
      zipCode,
    } = this.state;

    return (
      <DataEntry
        errors={errors}
        onUpdate={this.onUpdate.bind(this)}
        onChange={this.onChange.bind(this)}
      >
        <dl>
          <dt className="ui-required">Kraj</dt>
          <dd>
            <Field error={errors.country}>
              <NamedSelect
                value={country}
                items={countryOptions}
                onSelect={this.onCountrySelect.bind(this)}
              />
            </Field>
          </dd>
          {showVoivodeships && (
            <React.Fragment>
              <dt className="ui-required">Województwo</dt>
              <dd>
                <Field error={errors.voivodeship}>
                  <NamedSelect
                    value={voivodeship}
                    items={voivodeshipsOptions}
                    onSelect={this.onVoivodshipSelect.bind(this)}
                  />
                </Field>
              </dd>
            </React.Fragment>
          )}
          <dt className="ui-required">Miejscowość</dt>
          <dd>
            <TextField
              required
              name="city"
              error={!!errors.city}
              helperText={errors.city}
              onChange={this.onCityChange.bind(this)}
              value={city}
            />
          </dd>
          {showCommunity && (
            <React.Fragment>
              <dt className="ui-required">Gmina</dt>
              <dd>
                <Field error={errors.community}>
                  <NamedSelect
                    items={communityOptions}
                    onSelect={this.onCommunitySelect.bind(this)}
                    value={community}
                  />
                </Field>
              </dd>
            </React.Fragment>
          )}
          <dt className="ui-required">Kod pocztowy</dt>
          <dd>
            <TextField
              required
              name="zipCode"
              error={!!errors.zipCode}
              helperText={errors.zipCode}
              onChange={changeHandler.bind(this)}
              value={zipCode}
            />
          </dd>
          <dt className="ui-required">Ulica i numer domu/mieszkania</dt>
          <dd>
            <TextField
              required
              name="street"
              error={!!errors.street}
              helperText={errors.street}
              onChange={changeHandler.bind(this)}
              value={street}
            />
          </dd>
        </dl>
      </DataEntry>
    );
  }
}
